import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async () => {
  console.log("handle401Error called!");
  // Rest of your middleware logic
};

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    credentials: "include",
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    addProduct: builder.mutation({
      query: (body) => ({
        url: "products/create",
        method: "POST",
        body,
      }),
    }),
    getProducts: builder.query({
      query: ({ type = '', badge = '' }) => {
        let url = `products/public`;
        const params = [];
        if (type) params.push(`type=${encodeURIComponent(type)}`);
        if (badge) params.push(`badge=${encodeURIComponent(badge)}`);
        if (params.length) url += `?${params.join('&')}`;
        return url;
      },
    }),
    getProductDetail: builder.query({
      query: (id) => `products/public/${id}?type=BEST_SELLING`,
    }),
    getSupplierProducts: builder.query({
      query: () => `products`,
    }),
  }),
});

export const {
  useAddProductMutation,
  useGetProductsQuery,
  useGetProductDetailQuery,
  useGetSupplierProductsQuery,
} = productApi;
