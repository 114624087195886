import React, { useState } from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { STEPS_PAYLOAD_INITIAL_STATE } from "utils/constants";
import {
  BackgroundImage,
  InnerContainer,
  MainContainer,
  StyledSteps,
  StepsTitle,
} from "./styles";
import { useUpdateDropshipperMutation } from "api/authApi";
import cache from "utils/cache";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [currentStep, setCurrentSteps] = useState(0);
  const [stepsPayload, setStepsPayload] = useState(STEPS_PAYLOAD_INITIAL_STATE);

  const navigate = useNavigate();

  const [updateDropshipper] = useUpdateDropshipperMutation();

  const handleUpdateDropshipper = async (finalPayload) => {
    const res = await updateDropshipper(finalPayload);
    if (res.data) {
      console.log("res?.data?.user", res?.data?.user);
      cache.setItem("user", res?.data?.user);
      navigate("/landing_page");
    } else {
      message.error(res.error.message || "Something went wrong");
    }
  };

  const Next = () => {
    setCurrentSteps(currentStep + 1);
  };
  const items = [
    {
      subTitle: <StepsTitle>You</StepsTitle>,
      content: (
        <FirstStep
          Next={Next}
          setStepsPayload={setStepsPayload}
          stepsPayload={stepsPayload}
        />
      ),
    },
    {
      subTitle: <StepsTitle>Your Business</StepsTitle>,
      content: (
        <SecondStep
          Next={Next}
          setStepsPayload={setStepsPayload}
          stepsPayload={stepsPayload}
        />
      ),
    },
    {
      subTitle: <StepsTitle>Your Store</StepsTitle>,
      content: (
        <ThirdStep
          setStepsPayload={setStepsPayload}
          stepsPayload={stepsPayload}
          handleUpdateDropshipper={handleUpdateDropshipper}
        />
      ),
    },
  ];

  return (
    <BackgroundImage>
      <MainContainer>
        <InnerContainer>
          <StyledSteps
            current={currentStep}
            labelPlacement="vertical"
            responsive={false}
            items={items}
          />
          {items[currentStep].content}
        </InnerContainer>
      </MainContainer>
    </BackgroundImage>
  );
};

export default SignUp;
