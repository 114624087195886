import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async () => {
  console.log("handle401Error called!");
  // Rest of your middleware logic
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    signUp: builder.mutation({
      query: (credentials) => ({
        url: "auth/register",
        method: "POST",
        body: credentials,
      }),
    }),
    verifyUser: builder.query({
      query: () => "auth",
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
    }),
    updateDropshipper: builder.mutation({
      query: (payload) => ({
        url: "users/updateDropshipper",
        method: "POST",
        body: payload,
      }),
    }),
    getShippingAddress: builder.query({
      query: () => `users/shippingAddress`,
    }),
    addShippingAddress: builder.mutation({
      query: (payload) => ({
        url: "users/shippingAddress",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useVerifyUserQuery,
  useLogoutUserMutation,
  useUpdateDropshipperMutation,
  useGetShippingAddressQuery,
  useAddShippingAddressMutation,
} = authApi;
