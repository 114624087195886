import React, { useState } from "react";
import {
  AvatarWrapper,
  ButtonWrapper,
  InnerContainer,
  MainButton,
  MainContainer,
  MainHeading,
  StyledAvatar,
  AvatarText,
  TabsWrapper,
  StyledTabs,
} from "./styles";

import Avatar1 from "@/assets/Avatar.png";
import Avatar2 from "@/assets/Avatar1.png";
import Avatar3 from "@/assets/Avatar2.png";
import Avatar4 from "@/assets/Avatar3.png";
import Avatar5 from "@/assets/Avatar4.png";
import PriceCards from "../PriceCards";
const onChange = (key) => {
  console.log(key);
};

const UserList = [
  { name: "1", avatarUrl: Avatar1 },
  { name: "2", avatarUrl: Avatar2 },
  { name: "3", avatarUrl: Avatar3 },
  { name: "4", avatarUrl: Avatar4 },
  { name: "5", avatarUrl: Avatar5 },
];

const items = [
  {
    label: "Monthly",
    key: 1,
    children: <PriceCards />,
  },
  {
    label: "Yearly",
    key: 2,
    children: <PriceCards />,
  },
];

const Pricing = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <ButtonWrapper>
          <MainButton>{`Start Selling At ECOM DROPSHIP`}</MainButton>
        </ButtonWrapper>
        <MainHeading>{`Choose your Subscription `}</MainHeading>
        <AvatarWrapper>
          {UserList.map((user, index) => (
            <StyledAvatar
              key={index}
              style={{ backgroundColor: "#fff", verticalAlign: "middle" }}
              size="large"
              src={user.avatarUrl}
            ></StyledAvatar>
          ))}
          <AvatarText>{`Join 50+ merchants`}</AvatarText>
        </AvatarWrapper>
        <TabsWrapper>
          <StyledTabs onChange={onChange} type="card" items={items} />
        </TabsWrapper>
      </InnerContainer>
    </MainContainer>
  );
};

export default Pricing;
