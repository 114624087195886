import React from "react";
import { HeroSection, StyledBreadCrumbs, StyledImage } from "./styles";
import heroBanner from "@/assets/heroBanner.png";
import ProductSection from "./ProductSection";
import NewArrivalSection from "./NewArrivalSection";
import IconWithTextSection from "./IconWithTextSection";
import Layout from "layout";
import { useGetProductsQuery } from "api/productApi";
import Loader from "shared/Loader";

const items = [
  {
    title: <a href="">Product</a>,
  },
  {
    title: <a href="">Best Selling Products</a>,
  },
];

const BestSellingProductListPage = () => {
  const { data: bestSellingData, isLoading } = useGetProductsQuery({
    type: 'BEST_SELLING',
  });

  console.log('\n\n best selling \n\n', bestSellingData);
  return (
    <Layout>
      <StyledBreadCrumbs items={items} />
      <HeroSection>
        <StyledImage src={heroBanner} />
      </HeroSection>
      <ProductSection
        heading="Best Selling Products"
        products={bestSellingData?.products}
      />
      <NewArrivalSection />
      <IconWithTextSection />
      <Loader isLoading={isLoading} />
    </Layout>
  );
};

export default BestSellingProductListPage;
